import React, { useState, useEffect } from "react";
import { Base_Url } from "../../constants/constants"; // Assuming you have a base URL constant
import useToken from "../Login/useToken"; // Assuming you have a custom hook for token handling

type UserMetrics = {
  numUsers: number;
  numOnboarded: number;
  numLikes: number;
  numDislikes: number;
  numMatches: number;
  numMessages: number;
  numMale: number;
  numFemale: number;
  numNonbinary: number;
  numTransMan: number;
  numTransWomen: number;
  numGenderQueer: number;
};

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

// Create environment-specific cache keys using Base_Url
const getCacheKeys = (baseUrl: string) => {
  // Create a safe identifier from the base URL
  const envIdentifier = baseUrl.replace(/[^a-zA-Z0-9]/g, '_');
  return {
    metrics: `portal_${envIdentifier}_user_metrics`,
    timestamp: `portal_${envIdentifier}_user_metrics_timestamp`
  };
};

export const UsersDistributionDashboard = () => {
  const cacheKeys = getCacheKeys(Base_Url);

  const [metrics, setMetrics] = useState<UserMetrics | null>(() => {
    // Initialize from cache if available and not expired
    const cached = localStorage.getItem(cacheKeys.metrics);
    const timestamp = localStorage.getItem(cacheKeys.timestamp);
    
    if (cached && timestamp) {
      const isExpired = Date.now() - parseInt(timestamp) > CACHE_DURATION;
      if (!isExpired) {
        return JSON.parse(cached);
      }
      // Clear expired cache
      localStorage.removeItem(cacheKeys.metrics);
      localStorage.removeItem(cacheKeys.timestamp);
    }
    return null;
  });
  const { token } = useToken();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMetrics = async () => {
      // Skip fetch if we have valid cached data
      const timestamp = localStorage.getItem(cacheKeys.timestamp);
      if (timestamp && Date.now() - parseInt(timestamp) < CACHE_DURATION) {
        return;
      }

      setLoading(true);
      try {
        const url = `${Base_Url}/users/metrics`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data: UserMetrics = await response.json();
          setMetrics(data);
          // Cache the new data with timestamp
          localStorage.setItem(cacheKeys.metrics, JSON.stringify(data));
          localStorage.setItem(cacheKeys.timestamp, Date.now().toString());
        } else {
          throw new Error("Failed to fetch metrics");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [token, cacheKeys.metrics, cacheKeys.timestamp]);

  // Only show loading if we don't have any data yet
  if (loading && !metrics) {
    return <div>Loading metrics...</div>;
  }

  if (!metrics) {
    return <div>Failed to load metrics</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        marginBottom: "20px",
        justifyContent: "space-around",
      }}
    >
      <div>
        <strong>Num Users:</strong>
        <div>{metrics.numUsers}</div>
      </div>
      <div>
        <strong>Gender Distribution:</strong>
        <div>Male: {metrics.numMale}</div>
        <div>Female: {metrics.numFemale}</div>
        <div>Trans Man: {metrics.numTransMan}</div>
        <div>Trans Women: {metrics.numTransWomen}</div>
        <div>Gender Queer: {metrics.numGenderQueer}</div>
        <div>Non-Binary: {metrics.numNonbinary}</div>
      </div>
      <div>
        <strong>Like/Dislike Info</strong>
        <div>Likes: {metrics.numLikes}</div>
        <div>Dislikes: {metrics.numDislikes}</div>
        <div>Messages: {metrics.numMessages}</div>
        <div>Matches: {metrics.numMatches}</div>
      </div>
    </div>
  );
};