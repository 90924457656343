import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material';
import useToken from "../Login/useToken";
import { Base_Url } from "../../constants/constants";

interface VerificationSession {
  _id: string;
  userId: string;
  status: string;
  livenessSessionId: string;
  referenceImageUri: string;
  createdAt: string;
  updatedAt: string;
  imageSetHash: string;
  __v: number;
}

interface VerificationGridExpandedViewProps {
  userId: string;
}

const VerificationGridExpandedView: React.FC<VerificationGridExpandedViewProps> = ({ userId }) => {
  const [sessions, setSessions] = useState<VerificationSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { token } = useToken();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const url = new URL(`${Base_Url}/verifications/users/${userId}/sessions`);
        
        const options = {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
            'Content-Type': 'application/json',
          }),
        };

        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error('Failed to fetch sessions');
        }
        const data = await response.json();
        setSessions(data.sessions);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [userId, token]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2, color: 'error.main' }}>
        {error}
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ m: 2, boxShadow: 'none' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Session ID</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Reference Image</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell>Image Set Hash</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session) => (
            <TableRow key={session._id}>
              <TableCell>{session.livenessSessionId}</TableCell>
              <TableCell>{session.userId}</TableCell>
              <TableCell>{session.status}</TableCell>
              <TableCell>
                {session.referenceImageUri ? (
                  <img 
                    src={session.referenceImageUri} 
                    alt="Reference"
                    style={{ 
                      maxWidth: '200px', 
                      maxHeight: '300px', 
                      objectFit: 'contain' 
                    }} 
                  />
                ) : (
                  'No image'
                )}
              </TableCell>
              <TableCell>{new Date(session.createdAt).toLocaleString()}</TableCell>
              <TableCell>{new Date(session.updatedAt).toLocaleString()}</TableCell>
              <TableCell>{session.imageSetHash}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VerificationGridExpandedView; 
