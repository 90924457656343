import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Paper,
  TableSortLabel,
} from "@mui/material";
import VerificationGridExpandedView from "./VerificationGridExpandedView";

interface User {
  _id: string;
  id: string;
  firstName: string;
  lastName: string;
  verified: boolean;
  verification_session: {
    _id: string;
    userId: string;
    status: string;
    livenessSessionId: string;
    referenceImageUri: string;
    createdAt: string;
    updatedAt: string;
    imageSetHash: string;
  };
}

interface VerificationsGridProps {
  users: User[];
  selected: readonly User[];
  setSelected: (selected: readonly User[]) => void;
}

const VerificationsGridView = (props: VerificationsGridProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("desc");
  const [expandedUserId, setExpandedUserId] = useState<string | null>(null);
  const { users, selected, setSelected } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = () => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const sortedUsers = React.useMemo(() => {
    return [...users].sort((a, b) => {
      if (!a.verification_session) return 1;
      if (!b.verification_session) return -1;
      
      if (orderDirection === "asc") {
        return new Date(a.verification_session.createdAt).getTime() - new Date(b.verification_session.createdAt).getTime();
      }
      return new Date(b.verification_session.createdAt).getTime() - new Date(a.verification_session.createdAt).getTime();
    });
  }, [users, orderDirection]);

  const handleRowClick = (userId: string) => {
    setExpandedUserId(expandedUserId === userId ? null : userId);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="verifications table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Session ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <TableSortLabel
                active={true}
                direction={orderDirection}
                onClick={handleRequestSort}
              >
                Created At
              </TableSortLabel>
            </TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user) => (
              <React.Fragment key={user._id}>
                <TableRow 
                  onClick={() => handleRowClick(user.id)}
                  sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }}
                >
                  <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.verification_session?.livenessSessionId || 'N/A'}</TableCell>
                  <TableCell>{user.verification_session?.status || 'No Session'}</TableCell>
                  <TableCell>
                    {user.verification_session 
                      ? new Date(user.verification_session.createdAt).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {user.verification_session
                      ? new Date(user.verification_session.updatedAt).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                </TableRow>
                {expandedUserId === user.id && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ padding: 0 }}>
                      <VerificationGridExpandedView userId={user.id} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default VerificationsGridView; 
