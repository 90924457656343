import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { Base_Url } from "../../constants/constants";
import useToken from "../Login/useToken";
import VerificationsGridView from "./VerificationsGridView";

const VerificationsView = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [selected, setSelected] = useState<readonly any[]>([]);
  const { token } = useToken();

  async function getVerifications() {
    const url = new URL(`${Base_Url}/verifications/users`);
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data) {
          setUsers(data.users);
        } else {
          throw data.errorMessage;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getVerifications();
  }, []);

  return (
    <div className="verificationsGridWrapper">
      <div className="button">
        <Button variant="primary" onClick={getVerifications}>
          Refresh
        </Button>
      </div>
      <VerificationsGridView
        users={users}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default VerificationsView; 
